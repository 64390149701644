.consent-manager {
  position: fixed;
  bottom: -100;
  width: 100%;
  height: 0;
  background-color: #fff;
  transition: height 1s;
  z-index: 1022;
  padding: 10px 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.consent-manager.open {
  height: max-content;
  z-index: 1022;
  bottom: 0;
}

body.consent-manager-open {
  overflow: hidden;
}

body.consent-manager-open::after {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1021;
}

.consent-manager.open .title {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 16px;
}

.consent-manager.open p {
  width: 75%;
  text-align: center;
  color: #374151;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  word-wrap: break-word;
  margin: auto;
}

.consent-manager.open .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 12px;
}

.consent-manager.open .buttons button {
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 8px;
}

.consent-manager.open .buttons button:nth-child(1) {
  border-radius: 12px;
  background: #2f8ac2;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.consent-manager.open .buttons button:nth-child(2) {
  border-radius: 12px;
  border: 1px solid #2f8ac2;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: #2f8ac2;
  background-color: #fff;
}

.consent-manager.open .buttons button:nth-child(3) {
  background-color: transparent;
  color: #2f8ac2;
}

.consent-manager.open .checklists {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.consent-manager.open .checklists div label {
  margin-left: 6px;
}

.consent-manager.open .checklists div input {
  border-radius: 4px;
}

.consent-manager.open .checklists div input:checked {
  border-radius: 4px;
  accent-color: #2f8ac2;
}

/* Styles for larger screens */
@media (max-width: 768px) {
  .consent-manager.open p {
    width: 100%;
  }

  .consent-manager.open .checklists {
    margin-top: 20px;
  }

  .consent-manager.open .buttons {
    order: 2;
  }
}
